import { template as template_5463768d804a46e79492cd229e838796 } from "@ember/template-compiler";
const FKText = template_5463768d804a46e79492cd229e838796(`
  <p class="form-kit-text" ...attributes>
    {{yield}}
  </p>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKText;
