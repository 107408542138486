import { template as template_8387be953f9d43ec904c2d27503f02ec } from "@ember/template-compiler";
const FKLabel = template_8387be953f9d43ec904c2d27503f02ec(`
  <label for={{@fieldId}} ...attributes>
    {{yield}}
  </label>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKLabel;
