import { template as template_c9a97abc923a42d38d53d8d00796bb86 } from "@ember/template-compiler";
const SidebarSectionMessage = template_c9a97abc923a42d38d53d8d00796bb86(`
  <div class="sidebar-section-message-wrapper sidebar-row">
    <div class="sidebar-section-message">
      {{yield}}
    </div>
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default SidebarSectionMessage;
