import { template as template_757eb8688ee148e38f6d5c4474015215 } from "@ember/template-compiler";
import SortableColumn from "./sortable-column";
const RepliesCell = template_757eb8688ee148e38f6d5c4474015215(`
  <SortableColumn
    @sortable={{@sortable}}
    @number="true"
    @order="posts"
    @activeOrder={{@activeOrder}}
    @changeSort={{@changeSort}}
    @ascending={{@ascending}}
    @name="replies"
  />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default RepliesCell;
