import { template as template_06ea6a0b916f416397ed8ff31c6df5d3 } from "@ember/template-compiler";
import SortableColumn from "./sortable-column";
const LikesCell = template_06ea6a0b916f416397ed8ff31c6df5d3(`
  <SortableColumn
    @sortable={{@sortable}}
    @number="true"
    @order="likes"
    @activeOrder={{@activeOrder}}
    @changeSort={{@changeSort}}
    @ascending={{@ascending}}
    @name="likes"
  />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default LikesCell;
